<script setup lang="ts">
const navLinks = useNavigationLinks()
const impersonation = useImpersonate()
</script>

<template>
  <div
    class="sticky top-0 flex h-screen flex-col overflow-y-auto bg-secondary py-8 text-gray-300 2xl:py-16"
  >
    <img src="/Logo-IBH@2x.png" role="presentation" class="ml-6 w-44 2xl:ml-10" />
    <div class="mb-14 mt-8 lg:mt-16 2xl:mt-10">
      <template
        v-for="(route, iList) in navLinks.filter(
          (route) => !route.meta.layoutLocation || route.meta.layoutLocation === 'nav-list',
        )"
        :key="iList"
      >
        <StyledNuxtLink
          :to="{ path: route.path }"
          class="group flex flex-row items-stretch font-medium"
        >
          <div
            class="mr-2 flex grow items-center py-4 pl-6 group-hover:bg-ibh group-hover:shadow-2xl 2xl:pl-10"
          >
            <UIcon
              v-if="route.meta.icon"
              :name="route.meta.icon"
              class="h-8 w-8 rounded-lg 2xl:h-10 2xl:w-10"
            />
            <div v-else class="h-8 w-8 rounded-lg bg-gray-300 2xl:h-10 2xl:w-10"></div>
            <div class="ml-4 2xl:ml-6">{{ route.meta.label }}</div>
          </div>
          <div v-if="isSameRoute(route.path, useRoute().path)" class="ml-auto w-3 bg-ibh"></div>
          <div v-else class="ml-auto w-3"></div>
        </StyledNuxtLink>
      </template>
    </div>

    <div class="ml-3 mr-4 mt-auto flex flex-col 2xl:ml-7">
      <button
        type="button"
        class="flex items-center rounded-md px-2.5 py-1.5 text-left hover:bg-secondary-900"
        @click="useAuth().logout()"
      >
        <UIcon name="i-heroicons-arrow-left-on-rectangle" class="mr-2 h-5 w-5" />
        <span>Ausloggen</span>
      </button>
      <template
        v-for="(route, iFooter) in navLinks.filter(
          (route) => route.meta.layoutLocation === 'nav-footer',
        )"
        :key="iFooter"
      >
        <template v-if="route.meta.metaName === 'contact'">
          <StyledNuxtLink
            exact-active-class="font-medium"
            class="-order-1 rounded-md px-2.5 py-1.5 hover:bg-secondary-900"
            :to="{ path: route.path }"
          >
            <UIcon name="i-heroicons-pencil-square" class="mr-2 h-5 w-5" />
            <span>Kontakt</span>
          </StyledNuxtLink>
        </template>

        <template v-else-if="route.meta.metaName === 'profile' && route.meta.user">
          <StyledNuxtLink
            :to="{ path: route.path }"
            class="flex flex-row items-center justify-start gap-2 rounded-lg p-3 hover:bg-secondary-900 2xl:gap-5"
          >
            <div
              class="relative grid h-12 w-12 items-center justify-center bg-gray-400 clip-hexagon 2xl:h-16 2xl:w-16"
            >
              <span class="text-2xl font-semibold text-white">
                {{ firstLettersOf(route.meta.user!) }}
              </span>
            </div>
            <div class="flex-1 truncate">
              <div class="truncate">{{ route.meta.user?.username }}</div>
              <div class="truncate">
                {{ route.meta.user?.department }}
              </div>
            </div>
          </StyledNuxtLink>
        </template>
      </template>

      <UButton
        v-if="impersonation.status === 'active'"
        class="mt-4"
        size="xl"
        icon="i-ph-user-switch"
        @click="impersonation.endImpersonate"
      >
        Verkörperung beenden
      </UButton>
    </div>
  </div>
</template>
