<script setup lang="ts">
// ensure auth store and routing is loaded regardless of route
useAuth()

// const isLargeScreen = useMediaQuery('(min-width: 1024px)')

const { data: ownPermissions } = useOwnPermissions()
const readyToLoadpage = computed(() => ownPermissions.value !== undefined)

const impersonation = useImpersonate()
</script>

<template>
  <div class="flex h-full">
    <NavBar
      class="`fixed left-0 top-0 z-10 min-h-screen w-80 shrink-0 transition-transform"
    ></NavBar>
    <div class="relative flex min-w-0 shrink grow flex-col transition-all">
      <UButton
        icon="i-ph-list-bold"
        size="lg"
        variant="soft"
        class="pointer-events-none absolute left-3 top-3 opacity-0 transition"
      />

      <div v-if="impersonation.status === 'active'" class="mx-12 my-4 py-1">
        <UAlert
          title="Verkörperung eines Nutzers"
          description="Achten Sie darauf, dass Sie gerade einen Nutzer verkörpern!"
          icon="i-heroicons-exclamation-triangle"
          color="red"
        />
      </div>
      <div class="transition-none">
        <slot v-if="readyToLoadpage"></slot>
      </div>
    </div>
  </div>
</template>
